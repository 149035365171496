import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import JWTDecode from '../../utils/jwtDecode';
import { useQuery } from '../../utils/helpers';
import PreLoanRequestIndexV3 from '../../feature/preloan/v3';
import { patchOriginClick } from '../../queries/preloan/v3';
import LeadFormIndex from '../../components/LeadFormIndex';
import type { IncomingOrigin } from '../../components/LeadFormIndex/types';
import analytics from '../../utils/analytics';

export default function PreLoanIndex() {
  const query = useQuery();
  const history = useHistory();
  const {
    boot: bootIntercom,
    shutdown: shutdownIntercom,
  } = useIntercom();
  const searchParams = React.useMemo(() => query, [query]);
  const [loading, setLoading] = React.useState(false);
  const [preLoanId, setPreLoanId] = React.useState('');
  const [showForm, setShowForm] = React.useState(false);
  const [error, setError] = React.useState(false);
  const defaultOriginClickRes = { originLabel: 'web', partner: 'Banca.Me', partnerProducts: ['consumer, microLoan'] };
  const [origin, setOrigin] = React.useState<IncomingOrigin|undefined>(undefined);

  const navigation = window.performance.getEntriesByType('navigation')[0].toJSON().type;

  const handleSetShowForm = (show: boolean) => {
    if (show) {
      analytics.reset();
      analytics.identify(null, { isAnonymous: true });
    }
    setShowForm(show);
  };

  const originClick = async (params:{originIcomingData: string,
    click: boolean,
    token:string|null}) => {
    const { originIcomingData, click, token } = params;
    let originData: {originLabel: string,
      partner: string,
      partnerProducts: string[]} = defaultOriginClickRes;
    try {
      setLoading(true);
      const res = await patchOriginClick(originIcomingData, click && !token);
      originData = res.data;
    } catch (e) {
      originData = defaultOriginClickRes;
    }
    searchParams.set('origin', originData.originLabel);
    searchParams.set('partner', originData.partner);
    if (originData.partner === 'Banca.Me') {
      bootIntercom();
    } else {
      shutdownIntercom();
    }
    if (token) {
      searchParams.set('token', token);
    }
    history.push({
      search: searchParams.toString(),
    });
    return setOrigin(originData);
  };

  React.useEffect(() => {
    const token = query.get('token');
    const originIcomingData = query.get('origin') || 'web';
    originClick({ originIcomingData, click: navigation === 'navigate', token });

    if (token) {
      setLoading(true);
      try {
        const payload = JWTDecode(token);
        setPreLoanId(payload.preLoanRequestId);
        setShowForm(true);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    } else {
      setLoading(false);
    }
  }, [query.get('origin')]);

  return (
    <LeadFormIndex
      origin={origin}
      error={error}
      loading={loading}
    >
      {!loading && origin
        ? (
          <PreLoanRequestIndexV3
            handleShowForm={handleSetShowForm}
            preLoanId={preLoanId}
            showForm={showForm}
            origin={origin}
          />
        ) : <div />}
    </LeadFormIndex>

  );
}
