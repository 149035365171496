import {
  Backdrop, CircularProgress, Grid, Typography,
} from '@mui/material';
import React from 'react';
import LeadFormLeftGrid from './LeadFormLeftGrid';
import LeadFormRightGrid from './LeadFormRightGrid';
import useStyles from './styles';
import { LeadFormIndexProps } from './types';

export default function LeadFormIndex(props: LeadFormIndexProps) {
  const {
    origin, error, loading, children, fullScreen,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
    >
      <LeadFormLeftGrid
        xs={fullScreen ? 12 : undefined}
        lg={fullScreen ? 12 : undefined}
      >
        {children}
      </LeadFormLeftGrid>
      {!fullScreen
      && (
      <LeadFormRightGrid
        origin={origin}
      />
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
        {error && (
          <Typography color="inherit" maxWidth={450} marginTop={10}>
            Ha ocurrido un error en la verificación, por favor comienza de nuevo el flujo
            o contacte servicio al cliente
          </Typography>
        ) }
      </Backdrop>
    </Grid>
  );
}
