import React from 'react';
import {
  Backdrop,
  Divider,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import type { PLRFormProps } from '../../../../../../types/preloan';
import { postCreateLoanRequestFromPLR } from '../../../../../../queries/preloan';
import AcceptedLoanDetails from './components/AcceptedLoanDetails';
import ConfirmationDialog from './components/ConfirmationDialog';
import { FormSchemaV3 } from '../../../../../../types/preloan/v3';
import { postCheckout } from '../../../../../../queries/preloan/v3';
import avatarExcited from '../../../../../../assets/avatar_excited.svg';
import { partnerErrorRedirectURL, preLoanRequestStep } from '../../formLogic';
import analytics from '../../../../../../utils/analytics';

export default function LoanConfirmationForm
<T extends FormSchemaV3>(props: PLRFormProps<T>) {
  const {
    formRef,
    formSchema,
    handleEnableLoading,
    handleStep,
    handleCurrentData,
  } = props;
  const [isTriggered, setIsTriggered] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    analytics.page('PLR - RESULTADO', 'RESUMEN OFERTA', {
      productType: formSchema.loanParams.productType,
      amount: formSchema.requestLoanData.amount,
      term: formSchema.requestLoanData.periods,
      rate: formSchema.requestLoanData.interestRate,
      installmentAmount: formSchema.requestLoanData.installment,
      insurances: formSchema.requestLoanData.checkedInsurances,
    });
    handleEnableLoading(false);
  }, []);

  React.useEffect(() => {
    if (error) {
      const redirectURL = partnerErrorRedirectURL(formSchema.origin, formSchema.rut);
      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL; }, 1000);
      }
    }
  }, [error]);

  const createLoanRequest = async () => {
    try {
      setOpenBackdrop(true);
      const lr = await postCreateLoanRequestFromPLR(
        formSchema.PLRtoken,
      );
      window.location.href = lr.data.url;
    } catch (e) {
      setError(true);
      setOpenBackdrop(false);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={formSchema}
      onSubmit={async () => {
        setError(false);
        setLoading(true);
        try {
          handleEnableLoading(true);
          const res = await postCheckout(formSchema.PLRtoken);
          handleCurrentData({ ...formSchema, step: res.data.plr.step });
          switch (res.data.plr.step) {
            case 'COMPANY_CONTACT_INFO':
              return handleStep(preLoanRequestStep.COMPANY_CONTACT_INFO);
            case 'LOAN_SIMULATION': {
              handleEnableLoading(false);
              setLoading(false);
              setIsTriggered(false);
              return setIsTriggered(true);
            }
            case 'READY_FOR_LOAN':
              return handleStep(preLoanRequestStep.READY_FOR_LOAN);
            case 'COMPANY_APROVE':
              return handleStep(preLoanRequestStep.COMPANY_APPROVE);
            default:
              return setError(true);
          }
        } catch (e) {
          return setError(true);
        }
      }}
    >
      {({
        values,
      }) => (

        <Form>
          {!loading
            ? (
              <AcceptedLoanDetails
                currency={values.loanParams.currency}
                requestLoanData={values.requestLoanData}
              />
            )
            : (
              <>
                <br />
                <div>
                  <object data={avatarExcited} aria-labelledby="Avatar" height="80px" />
                </div>
                <Typography
                  gutterBottom
                  variant="h6"
                  fontWeight="bold"
                  align="center"
                  alignSelf="center"
                >
                  Comprobación de datos

                </Typography>
                <Divider />
                <br />
                <div>
                  <Typography>
                    Por favor espera un momento, esto podría tomar unos segundos...
                  </Typography>
                  <br />
                  <CircularProgress size={20} />
                </div>

              </>
            )}
          {error && (
          <Typography variant="caption" color="error">
            Ha ocurrido un error, por favor intente en otra ocasión o
            contáctenos
            al servicio al cliente.
          </Typography>
          )}
          <ConfirmationDialog
            formSchema={values}
            isTriggered={isTriggered}
            handleSubmit={createLoanRequest}
          />
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (th) => th.zIndex.drawer + 1301,
            }}
            open={openBackdrop}
          >
            <div>
              <CircularProgress
                color="inherit"
              />
            </div>
            <b style={{ marginLeft: 20 }}>
              Un momento...
            </b>
          </Backdrop>
        </Form>
      )}

    </Formik>
  );
}
