import React from 'react';
import { Link, Typography, useTheme } from '@mui/material';
import { FormSchemaV3, PreApproveType } from '../../../../../../../types/preloan/v3';
import PreApproveCard from '../../../../../../../components/PreApprove/PreApproveCard';

export const getContactMessage = (origin: string) => {
  switch (origin) {
    case 'bricsa':
      return ' puedes escribirnos a clientes@bricsa.cl o llamar al 600 394 000';
    case 'tricapitals':
      return ' puedes escribirnos a contacto@tricapitals.com o llamar al 2 23523553';
    case 'conquista':
      return ' puedes llamar al +56 2 2375 6931';
    case 'ecasa':
      return ' puedes llamar al +56 2 2925 4430';
    case 'ideal':
      return ' puedes llamar al +56 2 2929 2700';
    case 'IRentup':
      return ' puedes llamar al +56995195329';
    case 'buyDepa':
      return ' puedes escribirnos a contacto@buydepa.com o llamar al +569 6225 3968';
    case 'brick':
      return ' puedes llamar al +562 3323 4100';
    case 'maestra':
      return ' puedes llamar al +56 2 3210 6677';
    case 'norteVerde':
      return ' puedes llamar al +562 2838 6300';
    case 'rentaCapital':
      return ' puedes llamar al +569 6279 9730';
    case 'manquehue':
      return ' puedes llamar al +562 2750 7000';
    case 'fundamenta':
      return ' puedes escribirnos por WhatsApp al +562 2437 5500';
    case 'numancia':
      return ' puedes llamarnos al +56 32 238 5400';
    case 'crillon':
      return ' puedes llamar al +562 2351 4100';
    case 'smartycar':
      return ' puedes llamar al +569 3447 7319';
    case 'altura':
      return ' puedes escribir a contacto@ialtura.cl';
    case 'andes':
      return ' puedes escribir a contacto@iandes.cl';
    case 'metra':
      return ' puedes llamar a +562 2953 8767';
    case 'puelo':
      return ' puedes llamar al +569 3126 3837';
    case 'besalco':
      return ' puedes llamar al +562 2338 0800';
    case 'urmeneta':
      return ' puedes llamar al +562 2670 3000';
    case 'fai':
      return ' puedes llamar al +562 2530 9900';
    case 'icafal':
      return ' puedes escribirnos por WhatsApp al +562 2351 9200';
    case 'gpr':
      return ' puedes llamarnos al +562 2892 1100';
    case 'coopeuch':
      return ' puedes escribirnos por WhatsApp al +562 3304 8786';
    case 'nash':
      return ' puedes llamarnos al +562 3223 3347';
    case 'goplaceit':
      return ' puedes llamarnos a +569 3457 0216';
    case 'buenaventura':
      return ' puedes llamarnos a +569 2211 7800';
    case 'indesa':
      return ' puedes llamarnos a +56 22 761 5423';
    case 'inspira':
      return ' puedes llamarnos a +56 2 2942 5590';
    case 'vimac':
      return ' puedes llamar al +56 32 2680099';
    case 'libercoop':
      return ' puedes llamar al +562 2307 0400';
    case 'parinacoop':
      return ' puedes llamar al +56 9 3238 2725';
    case 'siena':
      return ' puedes escribir a contacto@siena.cl';
    default:
      return ' por favor envíanos un mensaje';
  }
};

export const getRedirectUrl = (origin: string, success: boolean, idNumber: string) => {
  switch (origin) {
    case 'bricsa':
      return 'https://www.bricsa.cl';
    case 'conquista':
      return 'https://iconquista.cl/';
    case 'ecasa':
      return 'https://ecasa.cl/';
    case 'brick':
      return 'https://ibrick.cl/';
    case 'manquehue':
      return 'https://imanquehue.com/';
    case 'inspira':
      return 'https://inspira.cl/';
    case 'ideal':
      return 'https://i-deal.cl/';
    case 'IRentup':
      return 'https://i-rentup.cl/';
    case 'fundamenta':
      return 'https://fundamenta.cl/';
    case 'crillon':
      return 'https://crillon.cl/';
    case 'besalco':
      return 'https://besalco.cl/';
    case 'altura':
      return 'https://ialtura.cl/';
    case 'andes':
      return 'https://iandes.cl/';
    case 'puelo':
      return 'https://puelogestion.com/';
    case 'gpr':
      return 'https://gpr.cl/';
    case 'euro':
      return 'https://euroinmobiliaria.cl/';
    case 'urmeneta':
      return 'https://urmeneta.cl/';
    case 'nash':
      return 'https://nash.cl/';
    case 'goplaceit':
      return 'https://www.pistos.cl/evaluacion/success';
    case 'buenaventura':
      return 'https://ibuenaventura.cl/';
    case 'fai':
      return 'https://ifai.cl/';
    case 'icafal':
      return 'https://icafal.cl/';
    case 'norteVerde':
      return 'https://norte-verde.cl/';
    case 'tricapitals':
      return 'https://tricapitals.com';
    case 'buyDepa':
      return 'https://buydepa.com';
    case 'maestra':
      return 'https://maestra.cl/';
    case 'coopeuch':
      return 'https://coopeuch.cl/';
    case 'rentaCapital':
      return 'https://renta-capital.cl/';
    case 'indesa':
      return 'https://www.indesa.cl/';
    case 'tenpo':
      return success ? 'https://tenpo.cl/?bancame_success' : 'https://tenpo.cl/?bancame_rejected';
    case 'brincus': {
      const url = window.location.host;
      if ((url.includes('dev') || url.includes('localhost') || url.includes('beta'))) {
        return success
          ? `https://www.brincus.com/dev/cuenta/bancame_exito?rut=${idNumber}`
          : `https://www.brincus.com/dev/cuenta/bancame_rechazo?rut=${idNumber}`;
      }
      return success
        ? `https://home.brincus.com/cuenta/bancame_exito?rut=${idNumber}`
        : `https://home.brincus.com/cuenta/bancame_rechazo?rut=${idNumber}`;
    }
    default:
      return 'https://banca.me';
  }
};

export const firstMessageSwitch = (step: FormSchemaV3['step'], preApprove: PreApproveType, origin: FormSchemaV3['origin']) => {
  const theme = useTheme();
  switch (step) {
    case 'WAITING_COMPANY_AFFILIATION':
      return (
        <b>
          Ahora nos pondremos en contacto con tu empleador.
          <span
            style={{ color: theme.customPalette.tertiary.main }}
          >
            Si tu empresa decide unirse al convenio Banca.Me

          </span>
          {' '}
          , podrás tomar tu crédito con descuento directo de tu sueldo.
        </b>
      );
    case 'READY_FOR_LOAN':
      return (
        <>
          <Typography
            component="div"
            style={{ color: theme.customPalette.tertiary.main }}
            gutterBottom
          >
            {preApprove?.productType === 'mortgage' || origin === 'IRentup' ? '¡Gracias por tu tiempo!' : '¡Tu solicitud ha sido recibida exitosamente!'}
          </Typography>
          {preApprove?.productType !== 'mortgage' || origin === 'IRentup'
            ? (
              <>
                <Typography
                  component="div"
                  gutterBottom
                >
                  Ahora evaluaremos tu caso y tendrás una respuesta a la brevedad.
                  {' '}
                </Typography>
                <br />
                <div>
                  <b>Te contactaremos lo antes posible</b>
                </div>
              </>
            )
            : (
              <>
                {origin === 'norteVerde' ? (
                  <Typography
                    component="div"
                    gutterBottom
                  >
                    Serás contactado por un ejecutivo de Norte Verde para continuar
                    con tu proceso de compra.
                  </Typography>
                ) : (
                  <Typography
                    component="div"
                    gutterBottom
                  >
                    ¡Felicidades! Según nuestros registros,
                    tu perfil califica para un crédito hipotecario:
                    <PreApproveCard preApprove={preApprove} />
                    {' '}
                  </Typography>
                )}
                <Typography
                  style={{ marginTop: 20 }}
                  variant="caption"
                  align="center"
                  component="div"
                  gutterBottom
                >
                  Banca.Me no otorga créditos hipotecarios.
                  {' '}
                  <Link style={{ textDecoration: 'underline' }} href="https://www.cmfchile.cl/educa/621/w3-propertyvalue-45015.html#:~:text=Los%20cr%C3%A9ditos%20hipotecarios%20pueden%20ser,ahorro%20y%20cr%C3%A9dito%20y%20mutuarias." target="_blank" rel="noopener">
                    {' '}
                    Solo instituciones reguladas por la CMF pueden hacerlo.
                  </Link>
                  {' '}
                  Esta simulación es referencial y
                  no representa una pre-aprobación de crédito hipotecario.
                </Typography>
              </>
            )}
        </>
      );

    case 'COMPANY_APROVE':
      return (
        <b>
          Con la información provista podremos
          {' '}
          <span
            style={{ color: theme.customPalette.tertiary.main }}
          >
            atenderte y entregarte una evaluación

          </span>
          {' '}
          lo antes posible.
        </b>
      );
    case 'ARCHIVED_SUCCESS':
      return 'Según nuestros registros tu perfil se ha calificado y archivado como exitoso';
    default:
      return 'Te contactaremos pronto';
  }
};
export const secondMessageSwitch = (step: FormSchemaV3['step']) => {
  switch (step) {
    case 'WAITING_COMPANY_AFFILIATION':
      return 'Si han pasado más de 5 días hábiles y no te hemos contactado,';
    case 'READY_FOR_LOAN':
      return 'Si tienes una duda o pregunta,';
    case 'COMPANY_APROVE':
      return 'Si tienes alguna inquietud,';
    case 'ARCHIVED_SUCCESS':
      return 'Si no te ha llegado información sobre esto,';
    default:
      return 'Si no te hemos contactado,';
  }
};

export const dataLayerLabels = (step: FormSchemaV3['step']) => {
  switch (step) {
    case 'WAITING_COMPANY_AFFILIATION':
      return 'Ingreso de datos empleador';
    case 'READY_FOR_LOAN':
      return 'Esperando aprobación mediante backoffice';
    case 'ARCHIVED_SUCCESS':
      return 'Lead exitoso. Este paso no debería ocurrir';
    case 'COMPANY_APROVE':
      return 'Esperando aprobación de empleador';
    default:
      return `Paso desconocido, no debería aprecer en esta etapa: ${step}`;
  }
};
