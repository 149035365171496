import React from 'react';
import { FormikProps } from 'formik';
import {
  formSchema, getButtonLabel, getStepContent,
} from './formLogic';
import type { PreLoanFormProps } from '../../../../types/preloan';
import { FormSchemaV3 } from '../../../../types/preloan/v3';
import LeadForm from '../../../../components/LeadFormIndex/LeadForm';

export default function PreLoanForm(props:PreLoanFormProps) {
  const {
    preLoanRequestId, preLoanRut, origin, amount,
    periods, installment, referralCode, optIn, onErrorReset,
  } = props;

  const [currentData, setCurrentData] = React.useState<FormSchemaV3>(
    {
      ...formSchema,
      id: preLoanRequestId,
      rut: preLoanRut,
      origin: origin?.originLabel || 'web',
      requestedAmount: +amount,
      requestedPeriods: +periods,
      requestedInstallment: +installment,
      referralCode,
      optIn,
      partner: origin.partner,
    },
  );

  const handleCurrentData = (data: FormSchemaV3) => setCurrentData(data);

  const rejectedFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const rutFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const dataFetchingFormRef = React.useRef <FormikProps<FormSchemaV3>>(null);
  const claveUnicaFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const uploadDocumentsFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const fillFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const loanSimulationFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const companyInfoFormRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const loanConfirmationRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const needsICPRef = React.useRef<FormikProps<FormSchemaV3>>(null);
  const lastStepRef = React.useRef<FormikProps<FormSchemaV3>>(null);

  const refsObject = {
    RejectedFormRef: rejectedFormRef,
    RutFormRef: rutFormRef,
    DataFetchingFormRef: dataFetchingFormRef,
    ClaveUnicaFormRef: claveUnicaFormRef,
    UploadDocumentsFormRef: uploadDocumentsFormRef,
    FillFormRef: fillFormRef,
    CompanyInfoFormRef: companyInfoFormRef,
    LoanSimulationFormRef: loanSimulationFormRef,
    LoanConfirmationRef: loanConfirmationRef,
    NeedsICPRef: needsICPRef,
    LastStepRef: lastStepRef,
  };

  const onSubmit = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in refsObject) {
      if (refsObject[key as keyof typeof refsObject]
        && refsObject[key as keyof typeof refsObject].current) {
        refsObject[key as keyof typeof refsObject].current?.handleSubmit();
      }
    }
  };
  return (
    <LeadForm
      title="Evaluación crediticia"
      stepperSteps={5}
      origin={origin}
      submitButtonProps={{
        disabledSteps: [2],
        getButtonLabel,
        testingLabel: 'preloan-submit-button',
      }}
      backButtonProps={{
        getButtonLabel: () => 'Volver',
        disabledSteps: [-1, 0, 1, 2, 3, 4, 5, 6, 8, 9],
      }}
      customBackSteps={[
        { activeStep: 2, customBackStep: 0 },
        { activeStep: 7, customBackStep: 5 },
      ]}
      getStepContent={(params: {
          activeStep: number,
          handleNext: () => void,
          handleStep: (step: number) => void,
          handleEnableLoading: (state: boolean)=> void}) => getStepContent(
        params.activeStep,
        currentData,
        refsObject,
        handleCurrentData,
        params.handleNext,
        params.handleStep,
        params.handleEnableLoading,
        preLoanRequestId,
      )}
      onSubmit={onSubmit}
      onErrorReset={onErrorReset}
    />
  );
}
