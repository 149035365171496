import React from 'react';
import {
  Divider,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import bancameLogoBlue from '../../../assets/bancame_logo_blue.svg';
import talanaLogo from '../../../assets/talana_logo_blue.webp';
import zubaleLogo from '../../../assets/zubale_logo_blue.webp';
import bricsaLogo from '../../../assets/BRICSA-LOGO.svg';
import rentaCapitalLogo from '../../../assets/rentacapital.svg';
import fundamentaLogo from '../../../assets/fundamenta.svg';
import faiLogo from '../../../assets/logoFai.svg';
import logoCrillon from '../../../assets/crillon.svg';
import logoAltura from '../../../assets/altura.svg';
import logoPuelo from '../../../assets/logo-puelo.svg';
import logoBesalco from '../../../assets/besalco.svg';
import logoColegual from '../../../assets/logoTresVolcanes.svg';
import logoGpr from '../../../assets/gpr-logo.svg';
import logoEuro from '../../../assets/logo_euro.svg';
import logoSiena from '../../../assets/siena_logo.svg';
import logoLibercoop from '../../../assets/libercoop.svg';
import logoParinacoop from '../../../assets/parinacoop.svg';
import logoML from '../../../assets/ml.svg';
import logoVimac from '../../../assets/vimac.svg';
import logoUrmeneta from '../../../assets/logo_urmeneta.svg';
import conquistaLogo from '../../../assets/logoIconquista.svg';
import ecasaLogo from '../../../assets/ecasa.svg';
import irentuplogo from '../../../assets/iRentUp.svg';
import uafLogo from '../../../assets/unidad_analisis_financiero_gobierno_chile.jpg';
import buyDepaLogo from '../../../assets/logoBuyDepa.svg';
import brickLogo from '../../../assets/brickLogo.svg';
import tricapitalslogo from '../../../assets/tricapitals.svg';
import norteVerdeLogo from '../../../assets/norteVerde.svg';
import inspiraLogo from '../../../assets/inspira.svg';
import manquehueLogo from '../../../assets/logoManquehue.svg';
import gespaniaLogo from '../../../assets/logoGespania.svg';
import pazLogo from '../../../assets/logoPaz.svg';
import buenaventuraLogo from '../../../assets/logo-buenaventura.svg';
import getPartnerButton from '../../../components/PartnerButtons';
import TermsAndConditionsLink from '../../../components/TermsAndConditionsLink';
import analytics from '../../../utils/analytics';

type PLRWelcomeProps = {
  amount: string,
  periods: string,
  handleSetShowForm: (show:boolean)=> void,
  origin: {originLabel: string, partner:string, partnerProducts: string[]},
}

const providerLogoSwitch = (providerName: string) => {
  switch (providerName) {
    case 'zubale':
      return zubaleLogo;
    case 'talana':
      return talanaLogo;
    default:
      return bancameLogoBlue;
  }
};

const mainLogoSwitch = (origin: string) => {
  switch (origin) {
    case 'bricsa':
      return bricsaLogo;
    case 'rentaCapital':
      return rentaCapitalLogo;
    case 'fundamenta':
      return fundamentaLogo;
    case 'crillon':
      return logoCrillon;
    case 'altura':
      return logoAltura;
    case 'puelo':
      return logoPuelo;
    case 'besalco':
      return logoBesalco;
    case 'gpr':
      return logoGpr;
    case 'euro':
      return logoEuro;
    case 'urmeneta':
      return logoUrmeneta;
    case 'fai':
      return faiLogo;
    case 'buyDepa':
      return buyDepaLogo;
    case 'conquista':
      return conquistaLogo;
    case 'ecasa':
      return ecasaLogo;
    case 'brick':
      return brickLogo;
    case 'inspira':
      return inspiraLogo;
    case 'tricapitals':
      return tricapitalslogo;
    case 'norteVerde':
      return norteVerdeLogo;
    case 'manquehue':
      return manquehueLogo;
    case 'gespania':
      return gespaniaLogo;
    case 'IRentup':
      return irentuplogo;
    case 'paz':
      return pazLogo;
    case 'siena':
      return logoSiena;
    case 'vimac':
      return logoVimac;
    case 'libercoop':
      return logoLibercoop;
    case 'parinacoop':
      return logoParinacoop;
    case 'buenaventura':
      return buenaventuraLogo;
    case 'portalInmobiliario':
      return logoML;
    case 'goplaceit':
      return '';
    case 'colegual':
      return logoColegual;
    default:
      return bancameLogoBlue;
  }
};

export default function PLRWelcome(props:PLRWelcomeProps) {
  const {
    amount, periods, handleSetShowForm, origin,
  } = props;
  const theme = useTheme();
  const [checked, setChecked] = React.useState(false);
  const handleSetChecked = () => setChecked((o) => !o);

  const getText = () => {
    if (origin.partnerProducts.some((product) => product === 'mortgage') && origin.originLabel !== 'goplaceit' && origin.originLabel !== 'IRentup') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación crediticia para descubrir si pre-calificas
          a un crédito hipotecario.
        </b>
      );
    }
    if (origin.partnerProducts.some((product) => product === 'mortgage') && origin.originLabel === 'goplaceit') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación crediticia para descubrir si pre-calificas
          a un crédito pie.
        </b>
      );
    }
    if (origin.partnerProducts.some((product) => product === 'microLoan') && origin.originLabel === 'tenpo') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación para descubrir si pre-calificas
          a un adelanto.
        </b>
      );
    }
    if (origin.partnerProducts.some((product) => product === 'microLoan') && origin.originLabel === 'smartycar') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación automática.
        </b>
      );
    }
    if (origin.partnerProducts.some((product) => product === 'mortgage') && origin.originLabel === 'IRentup') {
      return (
        <b style={{ color: theme.palette.text.primary }}>
          evaluación para arriendo.
        </b>
      );
    }
    return (
      <b style={{ color: theme.palette.text.primary }}>
        solicitud de un crédito responsable con nosotros.
      </b>
    );
  };

  React.useEffect(() => {
    analytics.page('PLR - ONBOARDING', 'BIENVENIDA');
  }, []);

  return (
    <Fade
      in
    >
      <div>
        <object
          data={mainLogoSwitch(origin.originLabel)}
          width={origin.originLabel === 'portalInmobiliario' ? '85%' : '220px'}
          aria-labelledby="Bancame Logo"
          style={{
            background: origin.originLabel === 'rentaCapital' ? '#071c35' : '',
            padding: '0.5em',
          }}
        />
        <Typography
          data-pw="preloan-welcome-header"
          variant="h4"
          style={{ maxWidth: 500, marginInline: 'auto', marginBlock: '1%' }}
        >
          ¡Te damos la bienvenida!
        </Typography>

        <br />
        <div style={{ maxWidth: 800 }}>
          <Divider />
          <Typography
            variant="body1"
            data-pw="preloan-welcome-text"
            style={{
              padding: '3%',
              lineHeight: '1.8',
              backgroundColor: origin.originLabel === 'bricsa' ? 'white' : 'whitesmoke',
            }}
          >
            {amount
              ? (
                <>
                  ¡Hola!
                  {' '}
                  Estás a punto de comenzar la
                  {' '}
                  <b style={{ color: theme.palette.text.primary }}>
                    solicitud de un crédito
                    responsable de
                    {` $${(+amount).toLocaleString('es-CL')}`}
                  </b>
                  {periods
                    ? (
                      <>
                        , en un plazo de
                        {` ${(+periods)} meses. `}
                      </>
                    ) : '. '}
                </>
              ) : (
                <>
                  ¡Hola! Estás a punto de comenzar una
                  {' '}
                  {getText()}
                  {' '}
                </>
              )}
            Te pediremos algunos datos para evaluar tu situación, como
            {' '}
            {(['talana', 'smartycar', 'IRentup'].includes(origin.originLabel)
            || origin.partnerProducts.some((product) => product === 'mortgage'))
              ? (
                <b style={{ color: theme.palette.text.primary }}>
                  tu RUT y ClaveÚnica.
                </b>
              ) : (
                <b style={{ color: theme.palette.secondary.main }}>
                  tu RUT,
                  tu última liquidación de sueldo y finalmente tu ClaveÚnica,
                  o tus últimas 12 cotizaciones de AFP.
                </b>
              )}
          </Typography>
          <Divider />
          <br />
          <TermsAndConditionsLink
            origin={origin.originLabel}
            checked={checked}
            handleSetChecked={handleSetChecked}
          />
        </div>
        <Typography
          variant="body2"
          style={{ padding: '2.5%', maxWidth: 500, margin: 'auto' }}
        >

          Por favor acepta los términos y condiciones, la política de privacidad y haz click en
          {' '}
          <span style={{ fontWeight: 'bold' }}>Comenzar</span>
          {' '}
          para continuar
        </Typography>
        {getPartnerButton({
          partner: origin.partner,
          onClick: () => handleSetShowForm(true),
          buttonLabel: 'Comenzar',
          endIcon: <ArrowForwardIcon style={{ color: 'inherit' }} />,
          testingLabel: 'preloan-welcome-button',
        })}
        {(['talana', 'zubale', 'brincus'].includes(origin.originLabel)
        || origin.partnerProducts.some((product) => product === 'mortgage'))
                  && (
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: '5px', display: 'flex', placeContent: 'center', color: 'gray',
                    }}
                    fontStyle="italic"
                  >
                    {'Alianza con '}
                    <object
                      style={{ marginLeft: 5 }}
                      data={providerLogoSwitch(origin.originLabel)}
                      width="80px"
                      height="22px"
                      aria-labelledby="Provider Logo"
                    />
                  </Typography>
                  ) }
        <Typography
          variant="body2"
          fontStyle="italic"
          style={{
            padding: '2.5%',
            marginLeft: '1em',
            marginRight: '1em',
          }}
        >

          Estamos regulados por la
          <a href="https://www.uaf.cl">
            <object
              style={{ marginLeft: 5, marginTop: '10%' }}
              data={uafLogo}
              width="100px"
              aria-labelledby="Provider Logo"
            />
          </a>
        </Typography>
      </div>
    </Fade>
  );
}
