import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/fondoweb_bancamines.webp';
import BricsaFondo from '../../assets/BRICSA-BANNER-ABANCAME.webp';
import FundamentaFondo from '../../assets/BancaMe_Fundamenta.webp';
import RentupFondo from '../../assets/IMAGEN_IRENTUP.webp';
import UrmenetaFondo from '../../assets/banner_urmeneta.webp';
import GoplaceitFondo from '../../assets/fondo_goplaceit.webp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  leftGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center ',
    },
  },

  rightGrid: {
    backgroundImage: `url(${BackgroundPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridGoplaceit: {
    backgroundImage: `url(${GoplaceitFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridBricsa: {
    backgroundImage: `url(${BricsaFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridIRentup: {
    backgroundImage: `url(${RentupFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridUrmeneta: {
    backgroundImage: `url(${UrmenetaFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightGridFundamenta: {
    backgroundImage: `url(${FundamentaFondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundColor: 'white',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
}));

export default useStyles;
