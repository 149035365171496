import React from 'react';
import {
  Fade, Grid,
} from '@mui/material';
import useStyles from '../styles';
import type { IncomingOrigin } from '../types';
import bancameLogoWhite from '../../../assets/bancame_logo_white.svg';

type LeadFormRightGridProps = {
    origin: IncomingOrigin|undefined,
    xs?: number|false,
    lg?: number|false,
}

const defaultProps = {
  xs: false,
  lg: 7,
};

export default function LeadFormRightGrid(props: LeadFormRightGridProps) {
  const { origin, xs, lg } = props;
  const classes = useStyles();
  const getClassNameRightGrid = (originLead: string) => {
    switch (originLead) {
      case 'bricsa':
        return classes.rightGridBricsa;
      case 'IRentup':
        return classes.rightGridIRentup;
      case 'fundamenta':
        return classes.rightGridFundamenta;
      case 'urmeneta':
        return classes.rightGridUrmeneta;
      default:
        return classes.rightGrid;
    }
  };
  return (
    <Fade
      in
      {...({ timeout: 2500 })}
    >
      <Grid
        item
        xs={xs}
        lg={lg}
        className={origin ? getClassNameRightGrid(origin.originLabel) : undefined}
        style={{ background: origin ? undefined : 'whitesmoke' }}
      >
        {origin && !origin.partnerProducts.some((product) => product === 'mortgage') && (
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >

          <img src={bancameLogoWhite} width="250px" alt="BancaMeLogo" aria-hidden />
        </div>
        )}
      </Grid>
    </Fade>
  );
}

LeadFormRightGrid.defaultProps = defaultProps;
