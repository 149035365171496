import React from 'react';
import { Typography } from '@mui/material';
import ExperianChallengeRut from './00_rut';
import ExperianChallengeForm from './01_form';
import { FormSchemaV3, PLRStepV3 } from '../../../../../../../../types/preloan/v3';
import AlertDialog from '../../../../../../../../components/AlertDialog';
import BancameButton from '../../../../../../../../components/BancameButton';
import { ExperianChallengeProps, ExperianFormProps } from './types';
import ExperianChallengeResult from './02_result';
import AgentContactButton from '../../../../../../../../components/AgentContactButton';
import { handleDataOK, handlePLRstateSwitch, returnDataForm } from '../../../02_dataFetching/components/helpers';
import { patchFillForm } from '../../../../../../../../queries/preloan/v3';
import analytics from '../../../../../../../../utils/analytics';

export const experianErrorSwitch = (errorCode: string) => {
  switch (errorCode) {
    case '10006':
      return 'Se ha bloqueado tu RUT por máximo de intentos permitidos, por favor vuelve más tarde (Este bloqueo puede durar hasta 72 horas).';
    case '10008':
      return 'Ha ocurrido un error verificando tu cédula. Por favor inténtalo de nuevo';
    case '10001':
      return 'Ha ocurrido un error verificando tu cédula. Por favor inténtalo de nuevo';
    default:
      return `Ha ocurrido un error (${errorCode}).`;
  }
};

export const termsAndConditions = `Por este acto, autorizo a mi AFP,
a entregar por intermedio de Previred, mis 12 últimos períodos de cotizaciones
previsionales a la Institución, con el fin de ser consideradas como antecedente
a esta solicitud comercial, dando así cumplimiento al artículo 4° de la ley N° 19.628
sobre Protección de la Vida Privada y a lo dispuesto en la ley N° 19.799
sobre Documentos
Electrónicos Firma Electrónica y Servicio de Certificación de Dicha Firma`;

const experianComponentSwitch = (props: ExperianChallengeProps
  & {step: number, refsObject: {
    rutComponentRef: React.MutableRefObject<any>,
    formComponentRef: React.MutableRefObject<any>}}) => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleSetDisableContinue,
    loading,
    error,
    step,
    handleSetStep,
  } = props;
  switch (step) {
    case 0:
      return (
        <ExperianChallengeRut
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleSetDisableContinue={handleSetDisableContinue}
          loading={loading}
          error={error}
          handleSetStep={handleSetStep}
        />
      );

    case 1:
      return (
        <ExperianChallengeForm
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleSetDisableContinue={handleSetDisableContinue}
          loading={loading}
          error={error}
          handleSetStep={handleSetStep}
        />
      );
    case 2:
      return (
        <ExperianChallengeResult
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleSetDisableContinue={handleSetDisableContinue}
          loading={loading}
          error={error}
          handleSetStep={handleSetStep}
        />
      );
    default:
      return <div />;
  }
};

const queryExperianCanalInstitucion = () => {
  if (window.location.href.includes('localhost') || window.location.href.includes('dev')) {
    return '00001';
  }
  return '00001';
};
const handleSubmit = async (props: {
  values: FormSchemaV3,
  step: number,
  handleSetError: (state: boolean) => void,
  handleSetLoading: (state: boolean)=> void,
  queriesArray: ExperianFormProps['queriesArray'],
  setFieldValue: ExperianFormProps['setFieldValue'],
  handleCurrentData: ExperianFormProps['handleCurrentData'],
  handleStep: ExperianFormProps['handleStep'],
  handleEnableLoading: ExperianFormProps['handleEnableLoading']}) => {
  const {
    values,
    step,
    handleSetError,
    handleSetLoading,
    queriesArray,
    setFieldValue,
    handleCurrentData,
    handleStep,
    handleEnableLoading,
  } = props;
  handleSetLoading(true);
  handleSetError(false);
  switch (step) {
    case 1: {
      const res = await queriesArray[0]({
        serialNumber: values.serialNumber,
        canalInstitucion: queryExperianCanalInstitucion(),
        token: values.PLRtoken,
      });
      setFieldValue('experianChallenge', res.data);
      if (res.data.errors.length > 0) {
        return handleSetError(true);
      }
      return handleSetLoading(false);
    }
    case 2: {
      const res = await queriesArray[1]({
        canalInstitucion: queryExperianCanalInstitucion(),
        challengeId: values.experianChallenge.data.RESUMEN.IDCHALLENGE,
        RESPUESTAS: Object.entries(values.experianChallengeResponses)
          .map(([key, value]) => ({ CODIGO_PREGUNTA: key, CODIGO_RESPUESTA: value })),
        token: values.PLRtoken,
      });
      handleSetLoading(false);
      if (res.data.errors.length > 0) {
        handleSetError(true);
      }
      if (res.data.success) {
        switch (res.data.plr.step) {
          case 'FILL_FORM': {
            const response = await patchFillForm(values.PLRtoken);
            const { dataForm, contactForm } = returnDataForm({
              rawDataForm: response.data.form,
              phone: response.data.plr.phone,
              email: response.data.plr.email,
              formSchema: values,
            });
            handleCurrentData({
              ...values, contactForm, dataForm, step: response.data.plr.step,
            });
            return handlePLRstateSwitch({
              plrState: response.data.plr.step,
              formSchema: { ...values, dataForm, contactForm },
              handleStep,
              handleSetDataLoading: handleSetLoading,
              handleEnableLoading,
            });
          }
          case 'UPLOAD_DOCUMENTS':
            handleCurrentData({
              ...values, step: res.data.plr.step,
            });
            return handlePLRstateSwitch({
              plrState: res.data.plr.step,
              formSchema: values,
              handleStep,
              handleSetDataLoading: handleSetLoading,
              handleEnableLoading,
            });
          case 'ARCHIVED_FAIL':
            handleCurrentData({
              ...values, step: res.data.plr.step,
            });
            return handlePLRstateSwitch({
              plrState: res.data.plr.step,
              formSchema: values,
              handleStep,
              handleSetDataLoading: handleSetLoading,
              handleEnableLoading,
            });
          case 'DATA_OK': {
            handleCurrentData({
              ...values, step: res.data.plr.step,
            });
            return handleDataOK({
              token: values.PLRtoken,
              newData: values,
              handleCurrentData,
              formSchema: values,
              handleSetPLRState: (newStep: PLRStepV3|'error'|false) => handlePLRstateSwitch({
                plrState: newStep,
                formSchema: values,
                handleStep,
                handleSetDataLoading: handleSetLoading,
                handleEnableLoading,
              }),
            });
          }
          default:
            return handleSetError(true);
        }
      }
      return setFieldValue('experianChallengeResult', res.data);
    }
    default:
      return handleSetLoading(false);
  }
};
export function getExperianComponent(
  props:ExperianFormProps,
) {
  const {
    values,
    handleChange,
    setFieldValue,
    queriesArray,
    handleCurrentData,
    handleStep,
    handleEnableLoading,
  } = props;

  const [disableContinue, setDisableContinue] = React.useState(false);
  const handleSetDisableContinue = (state: boolean) => setDisableContinue(state);
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleSetLoading = (state: boolean) => setLoading(state);
  const handleSetError = (state: boolean) => setError(state);
  const handleSetStep = (state: number) => setStep(state);
  const rutComponentRef = React.useRef<any>(null);
  const formComponentRef = React.useRef<any>(null);

  React.useEffect(() => {
    handleSubmit({
      values,
      step,
      handleSetError,
      handleSetLoading,
      queriesArray,
      setFieldValue,
      handleCurrentData,
      handleStep,
      handleEnableLoading,
    });
  }, [step]);

  React.useEffect(() => {
    analytics.page(values.isRedirect ? 'PLR - REDIRECCIÓN RUT'
      : 'PLR - SITUACIÓN LABORAL', 'CONSULTA PREVIRED');
    handleCurrentData({ ...values, isRedirect: false });
  }, []);

  const onClose = () => {
    analytics.track('Cerrar Dialog', {
      initiator: 'usuario',
    });
  };

  return (
    <AlertDialog
      onCloseAction={onClose}
      action={() => handleSetStep(step + 1)}
      button={<BancameButton label="Obtener Cotizaciones" displayFlex={false} />}
      submitButtonDisabled={disableContinue || loading || values.experianRetries > 2}
      closeButtonDisabled={loading}
      hideSubmitButton={step >= 2}
      hideCloseButton={values.experianChallengeResult.success}
      dialogContent={(
        <>
          {experianComponentSwitch({
            step,
            handleChange,
            handleSetDisableContinue,
            values,
            setFieldValue,
            loading,
            error,
            refsObject: { rutComponentRef, formComponentRef },
            handleSetStep,
          })}
          {values.experianRetries && values.experianRetries < 3
            ? (
              <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  style={{
                    backgroundColor: 'whitesmoke',
                    padding: 10,
                    margin: 5,
                  }}
                  borderRadius={20}
                  maxWidth={200}
                >
                  Reintentos:
                  {' '}
                  {values.experianRetries}
                  {' '}
                  de 2

                </Typography>
              </div>

            ) : null}
          {values.experianRetries > 2
            && (
              <div style={{
                textAlign: 'center', backgroundColor: 'whitesmoke', padding: 10, borderRadius: 20,
              }}
              >
                <Typography variant="body2">
                  Has alcanzado el máximo de reintentos permitido por la aplicación.
                  Si tienes problemas con el acceso, por favor contáctanos:
                </Typography>
                <br />
                <AgentContactButton
                  size="small"
                />
              </div>
            )}
        </>
      )}
    />
  );
}
