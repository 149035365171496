import React from 'react';
import { FormikProps } from 'formik';
import LeadForm from '../../../components/LeadFormIndex/LeadForm';
import { IncomingOrigin } from '../types';
import { getStepContent } from './formLogic';

type SignatureFormProps = {
  origin: IncomingOrigin
}

export default function SignatureForm(props: SignatureFormProps) {
  const { origin } = props;
  const [currentData, setCurrentData] = React.useState<any>({});
  const handleCurrentData = (data: any) => setCurrentData(data);

  const loadingFormRef = React.useRef<FormikProps<any>>(null);
  const refsObject = {
    LoadingFormRef: loadingFormRef,
  };
  const onSubmit = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in refsObject) {
      if (refsObject[key as keyof typeof refsObject]
        && refsObject[key as keyof typeof refsObject].current) {
        refsObject[key as keyof typeof refsObject].current?.handleSubmit();
      }
    }
  };
  return (
    <LeadForm
      title="Flujo de firma"
      stepperSteps={5}
      origin={origin}
      submitButtonProps={{
        disabledSteps: [],
        getButtonLabel: () => 'Continuar',
        testingLabel: 'signature-submit-button',
      }}
      backButtonProps={{
        getButtonLabel: () => 'Volver',
        disabledSteps: [0],
      }}
      getStepContent={(params: {
          activeStep: number,
          handleNext: () => void,
          handleStep: (step: number) => void,
          handleEnableLoading: (state: boolean)=> void}) => getStepContent(
        params.activeStep,
        currentData,
        refsObject,
        handleCurrentData,
        params.handleNext,
        params.handleStep,
        params.handleEnableLoading,
      )}
      onSubmit={onSubmit}
      onErrorReset={() => {}}
    />
  );
}
